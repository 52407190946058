<template>
  <div>
  ödeme Al
  </div>
</template>

<script>
export default {

}
</script>
